<template>
  <div class="DownloadApp">
    <h1 class="primary--text">האפליקציה שלנו</h1>
    <br />
    <h1 class="primary--text">
      כך מנהלים עסק במאה ה-21!
    </h1>
    <br />
    <p>
      אנו משתמשים בכלים הדיגיטליים החדשניים ביותר, לניהול העסק מכל מקום ומהנייד,
      ומאפשרים לכם לחסוך זמן יקר וכאב ראש!
    </p>
    <p>
      תנו לנו לדאוג לניירת ולבירוקרטיה, בזמן שאתם דואגים למה שחשוב: לגדול עם
      העסק להצלחה!
    </p>
    <p>
      <strong>
        ניתן גם לסלוק כרטיסי אשראי דרך האפליקציה בעמלה נמוכה במיוחד
      </strong>
    </p>
    <p>
      דרך האפליקציה שלנו תוכלו לשלוח את כל החומר שדרוש להנהלת החשבונות ישירות
      אלינו על ידי צילום פשוט של חשבוניות ההוצאה
    </p>
    <div>ללא צורך לאסוף ולהביא פיזית את המסמכים</div>
    <br />
    <br />

    <v-row justify="space-around">
      <v-col sm="6" md="4">
        <v-img max-height="80" contain src="../assets/report.svg"></v-img>
        <br />
        <h2 class="primary--text">הנפקת חשבוניות ודיווח הוצאות</h2>
        <p>
          האפליקציה מנפיקה עבורכם חשבוניות, קבלות, ומסמכים אחרים, ומעבירה אלינו
          חשבוניות הוצאה לדיווח ובקרה!
        </p>
      </v-col>

      <v-col sm="6" md="4">
        <v-img max-height="80" contain src="../assets/monitor.svg"></v-img>
        <br />
        <h2 class="primary--text">מצב העסק בכל רגע נתון</h2>
        <p>
          בנגיעה קלה במסך הנייד תדעו בדיוק מה מצב העסק, גבייה מלקוחות, הוצאות,
          ותשלומי מס. ככה מנהלים היום עסק!
        </p>
      </v-col>

      <v-col sm="6" md="4">
        <v-img max-height="80" contain src="../assets/credit-cards.svg"></v-img>
        <br />
        <h2 class="primary--text">סליקת אשראי</h2>
        <p>
          באפליקציה ניתן לסלוק כרטיסי אשראי בעמלה נמוכה במיוחד 1% ללא דמי שימוש!
        </p>
      </v-col>
    </v-row>

    <h1>לחצו להורדה</h1>

    <v-row justify="space-around">
      <v-col>
        <a
          href="https://play.google.com/store/apps/details?id=finbot.kbnapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
        >
          <img
            alt="Google Play כעת ב-"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/iw_badge_web_generic.png"
            height="120px"
          />
        </a>
      </v-col>

      <v-col align-self="center" class="pt-1">
        <v-btn
          x-large
          elevation="7"
          height="80px"
          class="black go-to-site-btn"
          href="https://kbn.finbot.co.il/hs-user"
          target="_blank"
        >
          <h2 class="white--text ml-3">כניסה לאתר</h2>
          <img alt="כניסת לקוחות" src="../assets/chrome.svg" height="60" />
        </v-btn>
      </v-col>

      <v-col align-self="center">
        <a
          href="https://apps.apple.com/us/app/%D7%97%D7%A9%D7%91%D7%95%D7%A0%D7%99%D7%AA-%D7%9C%D7%A2%D7%A1%D7%A7/id1551563861"
          target="_blank"
        >
          <img
            alt="App Store כעת ב"
            src="../assets/download_on_the_App_Store_Badge_IL_RGB_blk_102517.svg"
            height="80px"
          />
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DownloadApp",
  data: () => ({
    buttons: [
      {
        id: 0,
        text: "iphone",
        iconUrl: ""
      },
      {
        id: 0,
        text: "android",
        iconUrl: ""
      },
      {
        id: 0,
        text: "pc",
        iconUrl: ""
      }
    ]
  })
};
</script>

<style scoped lang="scss">
.go-to-site-btn {
  border-radius: 14px;
}
</style>
